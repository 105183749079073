import React from 'react'
import { StgHomeInfoProps } from './type'
import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image'
import Anchor from '@/components/base/anchor'
import Dots from '../dots/Dots'

const HomeInfo: React.FC<StgHomeInfoProps> = ({ title, desc, image, link }) => {
  return (
    <div style={{ backgroundColor: '#1A1A1A', position: 'relative' }}>
      <Container size="extended" className="stg-homeinfo-container">
        <Row
          className="stg-homeinfo-row"
          xl={{ align: 'center', justify: 'between' }}
        >
          <Column className="stg-homeinfo-column">
            <div className="stg-homeinfo">
              <div className="stg-homeinfo-texts">
                <div className="stg-homeinfo-title">
                  <h3 className="stg-homeinfo-title-info">{title}</h3>
                </div>

                {desc && (
                  <div
                    className="stg-homeinfo-description"
                    dangerouslySetInnerHTML={{ __html: desc }}
                  />
                )}
                <div className="stg-homeinfo-link">
                  <Anchor {...link} />
                </div>
              </div>

              <div className="stg-homeinfo-image">
                <div className="stg-homeinfo-image-before">
                  <Image {...image} alt="" />
                </div>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
      <div className="stg-homeinfo-dots">
        <Dots />
      </div>
    </div>
  )
}

export default HomeInfo
