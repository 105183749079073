import React from 'react'

const Dots = () => {
  return (
    <div className="dots">
      <div
        style={{
          backgroundColor: '#FBDED1',
          width: '10px',
          height: '10px',
          display: 'inline-block',
          borderRadius: '50%',
        }}
      />
      <div
        style={{
          backgroundColor: '#EFBFA9',
          width: '10px',
          height: '10px',
          display: 'inline-block',
          borderRadius: '50%',
        }}
      />
      <div
        style={{
          backgroundColor: '#E6A78C',
          width: '10px',
          height: '10px',
          display: 'inline-block',
          borderRadius: '50%',
        }}
      />
    </div>
  )
}

export default Dots
